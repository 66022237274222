import "./UploadPage.css";
import { useState } from "react";

function FormField({
    fieldTitle,
    fieldAttribute,
    fieldType,
    setterMethod,
    min,
    max,
}) {
    // TODO: might have to change this to reflect fieldType
    const [fieldValue, setFieldValue] = useState("");
    const loginAttributes = ["username", "password"];

    const handleFieldChange = (event) => {
        setFieldValue(event.target.value);

        if (loginAttributes.includes(fieldAttribute)) {
            setterMethod(event.target.value);
        } else {
            setterMethod(fieldAttribute, event.target.value);
        }
    };

    return (
        <div className="form-field">
            <p className="field-title">{fieldTitle}</p>
            <input
                className="field-input"
                type={fieldType}
                value={fieldValue}
                onChange={handleFieldChange}
                onWheel={(e) => e.target.blur()}
                min={min}
                max={max}
            />
        </div>
    );
}

export default FormField;
