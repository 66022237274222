import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "./ArticlePage.css";
import { customFetch } from "../Utils/CustomFetch";
import { NUM_TO_MONTH } from "../Utils/Constants";
import LoadingBar from "../LoadingBar/LoadingBar";
import CopyToClipboardLink from "./CopyToClipBoard";
import ReactMarkdown from 'react-markdown'
import { HiSparkles } from "react-icons/hi2";

function ArticlePage() {
    const { articleId } = useParams();
    const [articleData, setArticleData] = useState({
        abstract: "",
        article_id: 0,
        article_link: "",
        authors: "",
        citation: "",
        issue: 0,
        journal: "",
        journal_abbreviation: "",
        title: "",
        volume: 0,
        s3_working_paper_link: "",
        summary: "",
        summary_text_es: "",
        summary_text_fr: "",
        working_paper_month: 0,
        working_paper_year: -1,
        year: 0,
        tags: {}
    });

    const isAPICalledRef = useRef(false);
    const categoryOrder = ['sector', 'method', 'continent', 'region', 'country'];
    const [selectedLanguage, setSelectedLanguage] = useState("en"); // State for language selection

    useEffect(() => {
        // Fetch article data
        customFetch(`/article_info/${articleId}`)
            .then((res) => res.json())
            .then((result) => {
                if (result.article_id === -1) {
                    window.location.href = "/";
                    return;
                }
                setArticleData(result);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (!isAPICalledRef.current) {
            customFetch(`/add_article_visit/${articleId}`);
            isAPICalledRef.current = true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function addToStats(link) {
        customFetch(link + articleId);
    }

    function calcMonthYearString() {
        if (!articleData.working_paper_year) {
            return "";
        }

        if (!articleData.working_paper_month) {
            return articleData.working_paper_year;
        }
        return `${NUM_TO_MONTH[articleData.working_paper_month]}, ${
            articleData.working_paper_year
        }`;
    }

    function getSummaryByLanguage() {
        if (selectedLanguage === "es") {
            return articleData.summary_text_es || "No summary available in Spanish.";
        }
        if (selectedLanguage === "fr") {
            return articleData.summary_text_fr || "No summary available in French.";
        }
        return articleData.summary || "No summary available in English.";
    }

    return (
        <div className="article-page">
            {Boolean(articleData.article_id) ? (
                <>
                    <h1>{articleData.title}</h1>
                    <h2>{articleData.authors}</h2>
                </>
            ) : (
                <>
                    <LoadingBar
                        width={600}
                        height={60}
                        additionalStyles={{ marginBlock: "15px" }}
                    />
                    <LoadingBar
                        width={400}
                        height={30}
                        additionalStyles={{ marginBlock: "10px" }}
                    />
                    <LoadingBar
                        width={250}
                        height={25}
                        additionalStyles={{ marginBlock: "5px" }}
                    />
                </>
            )}
            <div className="article-bottom">
                <div className="article-summary-block">
                    <h4>Abstract</h4>
                    {articleData.abstract ? (
                        <p className="abstract">{articleData.abstract}</p>
                    ) : (
                        <LoadingBar
                            width={650}
                            height={200}
                            additionalStyles={{
                                marginTop: "15px",
                                marginBottom: "25px",
                            }}
                        />
                    )}
                    <h4>Summary</h4>

                    {!Boolean(articleData.article_id) ? (
                        <LoadingBar
                            width={650}
                            height={200}
                            additionalStyles={{
                                marginTop: "15px",
                                marginBottom: "25px",
                            }}
                        />
                    ) : (
                        <>
                            {Boolean(articleData.summary) ? (
                                <>
                                    <div className="flex" style={{ gap: '8px' }}>
                                        <HiSparkles style={{ 'color': 'var(--medium-gray)' }} />
                                        <h6>Generated by Artificial Intelligence</h6>
                                    </div>
    


<div className="language-buttons">
    <button
        className={`language-background-button button language-button ${selectedLanguage === 'en' ? 'active' : ''}`}
        onClick={() => setSelectedLanguage('en')}
    >
        English
    </button>
    <button
        className={`language-background-button button language-button ${selectedLanguage === 'es' ? 'active' : ''}`}
        onClick={() => setSelectedLanguage('es')}
    >
        Spanish
    </button>
    <button
        className={`language-background-button button language-button ${selectedLanguage === 'fr' ? 'active' : ''}`}
        onClick={() => setSelectedLanguage('fr')}
    >
        French
    </button>
</div>


                                    <ReactMarkdown children={getSummaryByLanguage()} />
                                    <h6 className="summary-disclaimer">
                                        Disclaimer: These summaries aim to offer
                                        quick insights but will not capture all nuances
                                        of the original work. For more information about
                                        the limitations see the “About this site" page <Link to="/about">here</Link>. The Spanish and French translations are generated using google translate.
                                    </h6>
                                </>
                            ) : (
                                <p>There is currently no summary for this article. Please check back later!</p>
                            )}
                        </>
                    )}
                </div>
                <div className="article-info-block">
                    {articleData.s3_working_paper_link &&
                        articleData.s3_working_paper_link !== "None" && (
                            <a
                                href={articleData.s3_working_paper_link}
                                target="_blank"
                                rel="noreferrer"
                                className="black-background-button button"
                                onClick={() => addToStats("/add_wp_visit/")}
                            >
                                Download Working Paper
                            </a>
                        )}
                    {articleData.s3_working_paper_link === "None" && (
                        <a
                            href={"/"}
                            target="_blank"
                            rel="noreferrer"
                            className="black-background-button button disabled-button"
                            onClick={(event) => event.preventDefault()}
                        >
                            No Working Paper Available
                        </a>
                    )}
                    {!articleData.s3_working_paper_link && (
                        <LoadingBar
                            width={267}
                            height={50}
                            additionalStyles={{ marginBlock: "24px" }}
                        />
                    )}

                    {articleData.article_link ? (
                        <a
                            href={articleData.article_link}
                            target="_blank"
                            rel="noreferrer"
                            className="white-background-button button"
                            onClick={() =>
                                addToStats("/add_article_link_visit/")
                            }
                        >
                            Read in Journal
                        </a>
                    ) : (
                        <LoadingBar
                            width={185}
                            height={50}
                            additionalStyles={{ marginBlock: "24px" }}
                        />
                    )}
                    <div className="article-info-section">
                        <h4>Publisher</h4>
                        {Boolean(articleData.article_id) ? (
                            <>
                                <p>{articleData.journal}</p>
                                {Boolean(articleData.issue) ? (
                                    <p>
                                        {articleData.year}, Issue{" "}
                                        {articleData.issue}
                                    </p>
                                ) : (
                                    <p>{articleData.year}</p>
                                )}
                            </>
                        ) : (
                            <LoadingBar
                                width={220}
                                height={50}
                                additionalStyles={{ marginBlock: "24px" }}
                            />
                        )}
                    </div>
                    {Boolean(articleData.working_paper_year) && (
                        <div className="article-info-section">
                            <h4>Working paper date</h4>
                            {articleData.article_id ? (
                                <p>{calcMonthYearString()}</p>
                            ) : (
                                <LoadingBar
                                    width={150}
                                    height={25}
                                    additionalStyles={{
                                        marginBlock: "5px",
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {Boolean(articleData.citation) ? (
                        <div className="article-info-section">
                            <div className="citation-text-line">
                                <h4 className="citation-text">Citation</h4>
                                <CopyToClipboardLink
                                    text={articleData.citation}
                                />
                            </div>
                            <p>{articleData.citation}</p>
                        </div>
                    ) : (
                        <>
                            <h4 className="citation-text">Citation</h4>
                            <LoadingBar
                                width={150}
                                height={25}
                                additionalStyles={{
                                    marginBlock: "5px",
                                }}
                            />
                        </>
                    )}
                    {Object.keys(articleData.tags).length > 0 ? (
                        <>
                            {!(articleData.tags.hasOwnProperty('None')) &&
                                <div className="article-info-section">
                                    <h4 className="tag-title-text">Tags</h4>
                                    {categoryOrder.map((category) => {
                                        return (
                                            <div key={category}>
                                                {category in articleData.tags &&
                                                    <>
                                                        <h5>{category}</h5>
                                                        <div className="tag-list">
                                                            {articleData.tags[category].map((tag) => {
                                                                return (
                                                                    <p className="tag-text" key={tag}>{tag}</p>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </>
                    ) : (
                        <>
                            <h4 className="tag-title-text">Tags</h4>
                            <LoadingBar
                                width={150}
                                height={25}
                                additionalStyles={{
                                    marginBlock: "5px",
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ArticlePage;
