let BASE_URL = "";
if (window.location.href.includes("localhost")) {
  BASE_URL = "http://127.0.0.1:8080";
}
export { BASE_URL };

export const S3_URL = 'https://ungated-research-dev-bucket.s3.amazonaws.com/';
export function getIntroductionS3Url(fileName) {
	return S3_URL + 'introduction/' + fileName + '.txt';
}
export function getWorkingPaperS3Url(fileName) {
	return S3_URL + 'pdf/' + fileName + '.pdf';
}
export const NUM_TO_MONTH = {
	1: "January",
	2: "February",
	3: "March",
	4: "April",
	5: "May",
	6: "June",
	7: "July",
	8: "August",
	9: "September",
	10: "October",
	11: "November",
	12: "December"
};
export const DEFAULT_ARTICLE_OBJECT = {
	id: 0,
	title: "title",
	authors: "authors",
	citation: "citation",
	abstract: "abstract",
	article_link: "article_link",
	working_paper_link: "working_paper_link",
	working_paper_month: 1,
	working_paper_year: 2024,
	journal: "journal",
	year: 2024,
	volume: 1,
	issue: 1
}