import LinearProgress from "@mui/material/LinearProgress";
// import { useRef } from 'react';

function LoadingBar({ width, height, additionalStyles, additionalBarStyles }) {
    //, minMobileWidth, minMobileHeight}) {
    // const mobile = useRef(window.innerWidth);

    if (!width) {
        width = 40;
    }
    if (!height) {
        height = 25;
    }
    // if (!minMobileWidth) {
    // 	minMobileWidth = "100%";
    // }
    // if (!minMobileHeight) {
    // 	minMobileHeight = minHeight;
    // }

    let normalStyle = {
        width: width,
        height: height,
        borderRadius: "15px",
        maxWidth: "100%",
    };

    let barStyle = {
        height: "100%",
    };

    if (additionalStyles) {
        normalStyle = { ...normalStyle, ...additionalStyles };
    }

    if (additionalBarStyles) {
        barStyle = { ...barStyle, ...additionalBarStyles };
    }

    // const mobileStyle = {
    // 	borderRadius: "15px",
    // 	minHeight: minMobileHeight,
    // 	minWidth: minMobileWidth,
    // 	maxWidth: "100%"
    // }
    const lightModeBackground =
    "linear-gradient(to right, rgb(245, 245, 245), rgb(225, 225, 225))";
  const lightModeProgressBarBackground =
    "linear-gradient(120deg, rgba(245,245,245, 1), rgba(220,220,220,0.5), rgba(245,245,245, 1))";

    return (
        <div
            className="loading-div"
            style={normalStyle}
            // style={mobile.current < 800 ? mobileStyle : normalStyle}
        >
            <LinearProgress
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "inherit",
                    background: lightModeBackground,
                    "& .MuiLinearProgress-bar": {
                        background: lightModeProgressBarBackground,
                        animationDuration: "3s",
                    },
                }}
                style={barStyle}
            />
        </div>
    );
}

export default LoadingBar;
