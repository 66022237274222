import { useEffect, useState } from "react";
import "./UploadPage.css";
import FormField from "./FormField";
import { DEFAULT_ARTICLE_OBJECT } from "../Utils/Constants";
import { Link } from "react-router-dom";
import { customFetch } from "../Utils/CustomFetch";

function UploadPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [page, setPage] = useState(0);
    const [articleInfo, setArticleInfo] = useState(DEFAULT_ARTICLE_OBJECT);
    const [message, setMessage] = useState("");
    const [scrapedIntroduction, setScrapedIntroduction] = useState("");
    const [pdf, setPDF] = useState("");

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update the articleInfo object when a form is changed
    const handleFormUpdate = (fieldTitle, newValue) => {
        setArticleInfo({ ...articleInfo, [fieldTitle]: newValue });
    };

    const checkUserInfo = (event) => {
        event.preventDefault();
        setPage(-1);

        const uploadObject = {
            username: username,
            password: password,
        };
        customFetch("/check_user_info", {
            method: "POST",
            body: JSON.stringify(uploadObject),
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Headers": "content-type",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.success) {
                    setMessage(
                        "Sorry, that username and password are invalid."
                    );
                    setPage(0);
                } else {
                    setMessage("You are now logged in!");
                    setPage(1);
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    // Submit Page 1 (basic information)
    const submitPage1 = (event) => {
        event.preventDefault();
        setPage(-1);

        // upload article info
        // TODO: Have to do this maybe?? --> ", year: parseInt(year)""
        const uploadObject = {
            ...articleInfo,
            uploadDate: new Date(),
            username: username,
            password: password,
        };
        customFetch("/upload_article", {
            method: "POST",
            body: JSON.stringify(uploadObject),
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Headers": "content-type",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if(!response.success) {
                    setMessage("Error: " + response.message);
                    setPage(1);
                    return;
                }
                let new_message =
                    "Success: " +
                    response.success.join(", ") +
                    "\n" +
                    "Failed: " +
                    response.fail.join(", ") +
                    "\n";
                new_message += "Article ID: " + response.article_id + "\n";
                if (response.working_paper_link) {
                    new_message +=
                        "Working Paper S3 Link: " +
                        response.working_paper_link +
                        "\n";
                    if (response.introduction) {
                        new_message += "See scraped inroduction below";
                        setScrapedIntroduction(response.introduction);
                    } else {
                        new_message +=
                            "Introduction could not be extracted. Please copy it from the article and paste below.";
                    }
                    setPage(3);
                } else {
                    new_message +=
                        "Working paper could not be downloaded. Please upload it manually below.";
                    setPage(2);
                }

                setMessage(new_message);
                handleFormUpdate("id", response.article_id);
            })
            .catch((error) => {
                setMessage(error.message);
                setPage(1);
            });
    };

    // Manual PDF Upload
    const submitPage2 = (event) => {
        event.preventDefault();
        setPage(-1);

        // GET PDF that was uploaded
        const data = new FormData();
        data.append("pdf", pdf);
        data.append("article_id", articleInfo.id);
        data.append("username", username);
        data.append("password", password);

        customFetch("/upload_pdf_only", {
            method: "POST",
            body: data,
        })
            .then((response) => response.json())
            .then((response) => {
                if(!response.success) {
                    setMessage("Error: " + response.message);
                    setPage(2);
                    return;
                }
                let new_message = "Article ID: " + response.article_id + "\n";
                if (response.working_paper_link) {
                    new_message +=
                        "Working Paper S3 Link: " +
                        response.working_paper_link +
                        "\n";
                    if (response.introduction) {
                        new_message += "See scraped inroduction below";
                        setScrapedIntroduction(response.introduction);
                    } else {
                        new_message +=
                            "Introduction could not be extracted. Please copy it from the article and paste below.";
                    }
                    setPage(4);
                } else {
                    new_message +=
                        "Working paper could not be downloaded. Please upload it manually below.";
                    setPage(3);
                }
                setMessage(new_message);
            })
            .catch((error) => {
                setMessage(error.message);
                setPage(2);
            });
    };

    const submitPage3 = (event) => {
        event.preventDefault();
        setPage(-1);

        // upload introduction to S3
        // create summary

        const uploadObject = {
            article_id: articleInfo.id,
            introduction: scrapedIntroduction,
            username: username,
            password: password
        };
        customFetch("/upload_introduction", {
            method: "POST",
            body: JSON.stringify(uploadObject),
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Headers": "content-type",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if(!response.success) {
                    setMessage("Error: " + response.message);
                    setPage(3);
                    return;
                }

                let message = response.message + "\n";
                if (response.link) {
                    message +=
                        "Link to uploaded introduction: " + response.link;
                    setPage(4);
                } else {
                    setPage(3);
                }
                setMessage(message);
            })
            .catch((error) => {
                setMessage(error.message);
                setPage(3);
            });
    };

    const backToPage1 = () => {
        setArticleInfo(DEFAULT_ARTICLE_OBJECT);
        setMessage("");
        setScrapedIntroduction("");
        setPage(1);
    };

    return (
        <div className="upload-page">
            <h1>Upload a New Article</h1>
            {page === -1 && (
                <h2>Loading... (replace with loading bar later)</h2>
            )}
            {page === 0 && (
                <form className="form" onSubmit={checkUserInfo}>
                    <div className="form-section">
                        <h2>User Information</h2>
                        <FormField
                            fieldTitle={"Username"}
                            fieldAttribute={"username"}
                            fieldType={"text"}
                            setterMethod={setUsername}
                        />
                        <FormField
                            fieldTitle={"Password"}
                            fieldAttribute={"password"}
                            fieldType={"text"}
                            setterMethod={setPassword}
                        />
                    </div>
                    <input
                        className="submit-button"
                        type="submit"
                        value="Login"
                    />
                    <p className="message">{message}</p>
                </form>
            )}
            {page === 1 && (
                <form className="form" onSubmit={submitPage1}>
                    <em>If there is missing required information, submit the 
                        form with a dummy value (like "MISSING") and tell Zane 
                        or Olivia about the issue. If you submit inccorect information
                        accidentally, please let us know so we can fix it.
                    </em>
                    <div className="form-section">
                        <h2>Article Information</h2>
                        <FormField
                            fieldTitle={"Article Title"}
                            fieldAttribute={"title"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Authors"}
                            fieldAttribute={"authors"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Citation"}
                            fieldAttribute={"citation"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Abstract"}
                            fieldAttribute={"abstract"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Article Link"}
                            fieldAttribute={"article_link"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Working Paper Link (leave blank if none)"}
                            fieldAttribute={"working_paper_link"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={
                                "Working Paper Month (number from 1-12 and if not known, put 0)"
                            }
                            fieldAttribute={"working_paper_month"}
                            fieldType={"number"}
                            setterMethod={handleFormUpdate}
                            min={1}
                            max={12}
                        />
                        <FormField
                            fieldTitle={"Working Paper Year (if not known, put 0)"}
                            fieldAttribute={"working_paper_year"}
                            fieldType={"number"}
                            setterMethod={handleFormUpdate}
                            min={1900}
                            max={new Date().getFullYear() + 1}
                        />
                    </div>
                    <div className="form-section">
                        <h2>Issue Information</h2>
                        <FormField
                            fieldTitle={
                                "Journal Name (ex. Quarterly Journal of Economics)"
                            }
                            fieldAttribute={"journal"}
                            fieldType={"text"}
                            setterMethod={handleFormUpdate}
                        />
                        <FormField
                            fieldTitle={"Year"}
                            fieldAttribute={"year"}
                            fieldType={"number"}
                            setterMethod={handleFormUpdate}
                            min={1900}
                            max={new Date().getFullYear() + 1}
                        />
                        <FormField
                            fieldTitle={"Volume Number"}
                            fieldAttribute={"volume"}
                            fieldType={"number"}
                            setterMethod={handleFormUpdate}
                            min={0}
                            max={1000}
                        />
                        <FormField
                            fieldTitle={"Issue Number (if no issue, put 0)"}
                            fieldAttribute={"issue"}
                            fieldType={"number"}
                            setterMethod={handleFormUpdate}
                            min={0}
                            max={1000}
                        />
                    </div>
                    <input
                        className="submit-button"
                        type="submit"
                        value="Next"
                    />
                </form>
            )}
            {page === 2 && (
                <form className="form" onSubmit={submitPage2}>
                    <em className="message">{message}</em>
                    <input
                        type="file"
                        accept=".pdf"
                        onChange={(event) => setPDF(event.target.files[0])}
                    />
                    <input
                        className="submit-button"
                        type="submit"
                        value="Next"
                    />
                </form>
            )}
            {page === 3 && (
                <form className="form" onSubmit={submitPage3}>
                    <em className="message">{message}</em>
                    <textarea
                        className="introduction-box"
                        placeholder="Paste introduction here"
                        value={scrapedIntroduction}
                        onChange={(event) =>
                            setScrapedIntroduction(event.target.value)
                        }
                        rows={10}
                    ></textarea>
                    <input
                        className="submit-button"
                        type="submit"
                        value="Submit"
                    />
                </form>
            )}
            {page === 4 && (
                <>
                    <em>Thanks for uploading an article!</em>
                    <em>
                        You can find the article page
                        <Link to={`/article/${articleInfo.article_id}`}>
                            here
                        </Link>
                        (summary may not have been generated yet)
                    </em>
                    <button onClick={backToPage1}>
                        Upload another article
                    </button>
                </>
            )}
        </div>
    );
}
export default UploadPage;

/*
TODO: HOW TO DO A MULTI-STEP FORM??
TODO: MAKE A NEW ARTICLE_UPLOAD TABLE THAT HAS COLUMNS: ARTICLE_ID, UPLOAD_DATE, UPLOAD USERNAME

Info needed in original form:
-Article Info: Title, Authors, Citation, Abstract, article link, wp link, wp month, wp year
-Issue Info: Journal name, year, volume number, issue number

-Put all of that into the db and try to download pdf
	-if success: try to scrape introduction
	-if failure, have upload pdf option (SHOULD BE "NO PDF" OPTION THAT LEAVES IT BLANK)

-Try to scrape introduction
	-if success: return introduction in text box
	-if failure: give blank text box and have RA paste introduction

-generate AI-summary
*/
