import "./HomePage.css";
import SearchBar from "./SearchBar";
import { useEffect, useState } from "react";
import { customFetch } from "../Utils/CustomFetch";
import SearchBarResults from "./SearchBarResults";

function HomePage() {
    const [allJournals, setAllJournals] = useState([]);
    const [allArticles, setAllArticles] = useState([]);

    const [filters, setFilters] = useState([]);
    const [filterToCategory, setFilterToCategory] = useState({});

    const [filteredJournals, setFilteredJournals] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [filteredJournalCount, setFilteredJournalCount] = useState(0);
    const [filteredArticleCount, setFilteredArticleCount] = useState(0);

    const [loadingJournalData, setLoadingJournalData] = useState(true);

    const [loadingSearchResults, setLoadingSearchResults] = useState(false);

    useEffect(() => {
        // Fetch search bar data
        customFetch("/search_bar_data", {})
            .then((res) => res.json())
            .then((result) => {
                setAllJournals(result.journals);
                const preprocessedArticles = result.articles.map(article => {
                    return {
                        ...article,
                        tags: new Set(article.tags.split("|").map(str => parseInt(str)))
                    };
                });
                setAllArticles(preprocessedArticles);
                setFilteredJournals(result.journals);
                setLoadingJournalData(false);
            });
           
        customFetch("/all_filters", {})
         .then((res) => res.json())
         .then((result) => {
            let newFilters = []

            for (let i=0; i < result.length; i++) {
                newFilters.push({label: result[i].label, showAll: false, options: result[i].options});
            };

            let newFilterToCategory = {};
            for (let i=0; i < result.length; i++) {
                for (let j=0; j < result[i].options.length; j++) {
                    newFilterToCategory[result[i].options[j].id] = result[i].label;
                }
            }
    
            setFilters(newFilters);
            setFilterToCategory(newFilterToCategory);
         })
         
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="home-page">
            <div className="home-page-bottom" id="search-area">
                <SearchBar
                    allJournals={allJournals}
                    allArticles={allArticles}
                    filters={filters}
                    setFilters={setFilters}
                    setFilteredJournals={setFilteredJournals}
                    setFilteredArticles={setFilteredArticles}
                    setFilteredJournalCount={setFilteredJournalCount}
                    setFilteredArticleCount={setFilteredArticleCount}
                    dropdown={false}
                    placeholder={"SEARCH FOR JOURNALS, ARTICLES, TOPICS"}
                    setLoadingSearchResults={setLoadingSearchResults}
                    filterToCategory={filterToCategory}
                />

                <SearchBarResults
                    filteredArticles={filteredArticles}
                    filteredJournals={filteredJournals}
                    filteredJournalCount={filteredJournalCount}
                    filteredArticleCount={filteredArticleCount}
                    loadingJournalData={loadingJournalData}
                    homePage={true}
                    loadingSearchResults={loadingSearchResults}
                />
            </div>
        </div>
    );
}

export default HomePage;
