import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={10} square {...props} />
))(({ expanded }) => ({
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    // paddingBlock: '10px',
    // paddingInline: '15px',
    boxShadow: "none",
    backgroundColor: "none",
    padding: "10px 10px",
    "@media (max-width: 800px)": {
        padding: "5px 10px",
    },
    borderRadius: "15px",
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowDropDownIcon />} {...props} />
))(({ theme }) => ({
    backgroundColor: "var(--silver)",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    fontSize: "18px",
    height: "fit-content",
    minHeight: "33.5px",
    padding: "10px 20px",
    // flexDirection: "row-reverse",
    // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    //     transform: "rotate(90deg)",
    // },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
        marginTop: "0px",
        marginBottom: "0px",
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    marginLeft: "25px",
	padding: "8px",
    paddingBottom: "0px",
    borderTop: "1px solid var(--primary-text)",
    "& .MuiCollapse-wrapperInner": {
        marginBottom: "0px",
    },
}));
