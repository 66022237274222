import { useEffect } from "react";
import "./OtherPages.css";

function ContactUsPage() {
    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <p>Contact us at: <strong>ungatedresearch@outlook.com</strong></p>
        </div>
    );
}
export default ContactUsPage;
