import Dropdown from "./Dropdown";
import "./OtherPages.css";
import LoadingBar from "../LoadingBar/LoadingBar";
import { useEffect, useState } from "react";
import { customFetch } from "../Utils/CustomFetch";
import { Link } from "react-router-dom";

function AboutPage() {

    const [journalCount, setJournalCount] = useState(0);
    const [issueCount, setIssueCount] = useState(0);
    const [articleCount, setArticleCount] = useState(0);

    const [loadingCounts, setLoadingCounts] = useState(true);

    useEffect(() => {
        // Fetch journal, issue, and article counts
        customFetch("/get_counts", {})
            .then((res) => res.json())
            .then((result) => {
                setJournalCount(result.journals);
                setIssueCount(result.issues);
                setArticleCount(result.articles);
                setLoadingCounts(false);
            });
         
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const purposeContent = <div>
        <p>
        Recent <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4691124" target="_blank" rel="noreferrer">research</a> shows that high pricing of academic journals present barriers
        for researchers, especially in low income countries. After all, reading 
        recent published work is essential to learn about new methods and identify 
        potential contributions to the literature. 
        
        <br/> <br/>

        The website provides two main services:
        </p>
        <ol>
        <li>Assemble and provide access to all publicly available <strong>working papers</strong> for 
                research in leading economics journals in one place</li>
            <li>Provide AI-generated <strong>non-technical summaries</strong> of research for these papers</li>
        </ol>
        <p>

        Given the limitations of AI-generated content, we envision that these two 
        services are used jointly: summaries are used as <u>starting points</u> for engagement 
        with the full papers. Below, we provide more information about the method and limitations.
    
        <br/> <br />
        This website is an attempt to provide a public good to the research community 
        and students. We will never charge fees, have advertisements, or collect user 
        data (other than website traffic statistics). The website is housed out of Bowdoin College.  
        </p>
    </div>

    const howToUseContent = <div>
        <p>
            There are different ways you can use the website: 
        </p>
        <ul>
        <li>Browse by journal to get a broad overview of (newly) published work. 
            Simply click on the journal of interest on the <Link to="/">home page</Link>.  </li>
            <li>Use the <strong>filter function</strong> (circled in blue) to get a list of articles in a certain sector, location or use a specific method.</li>
            <li>Use the <strong>search bar</strong> (circled in red) to look for specific keywords or authors. You can use this in combination with the filter function.</li>
            <img src="/search-bar-demo.png" alt="search-bar-demo"/>
            <li>One of the most useful functions is to <strong>filter a combination of tags</strong> - e.g. searching for field experiments in behavioral economics in certain locations. Articles that meet these criteria automatically show up on the left.  </li>
            <img src="/search-bar-demo-2.png" alt="search-bar-demo-2"/>
        </ul>
    </div>

    const methodContent = <div>
        <p>We use the following steps to compile the database and AI generated summary:</p>
        <ul>
            <li>Identify titles from journals’ publicly available table of contents</li>
            <li>Search the internet for publicly available working papers of articles. We are able to find working papers for around 95% of journal articles.</li>
            <li>Copy the introduction of each article into generative AI (Gemini) and use a standardized prompt (see <Link to='/faq'>FAQ</Link>) to create a summary</li>
        </ul>
    </div>

    const limitationsContent = <div>
        <h4>Depth of summaries</h4>
        <p>
            The summaries provided on this website are generated by AI 
            (specifically by Gemini), based on the introduction of the working 
            paper version of academic articles. These summaries aim to offer 
            quick insights but will not capture all nuances of the original work.
             It may also not fully reflect the authors' intentions. It's also 
             important to acknowledge that AI may occasionally produce 
             "hallucinations", or inaccuracies not present in the original text.
            We envision that AI-generated summaries are used as <strong>starting points </strong>
            for engagement rather than definitive interpretations. For comprehensive
             understanding and to engage critically with the subject matter, 
             readers are encouraged to consult the full articles that can be 
             accessed via the “Download Working Paper” button.
        </p>
        <br/>
        <h4>Assessing quality of summaries</h4>
        <p>
            To assess the quality of summaries, we contacted a (non-random) 
            group of 20 researchers who have published in these journals and 
            shared summaries of one of their articles using our standardized 
            prompt. We asked them to give confidential feedback on the accuracy
             of the summaries. The graph below shows the accuracy ratings 
             (on a 1-10 point scale) for aspects of the summaries and the tags.
              The overall accuracy is relatively high (7.63 points) - however, 
              none of the researchers gave the maximum 10 score (and one person 
              said their research would “not get a passing grade”). The two 
              highest scoring categories were research question (8.28) and findings
               (8.24). Two summary categories (“Theory” and “Limitations”) only
                received accuracy scores between 5 and 6 and were thus excluded
                 from AI summaries provided on the website. Ratings on <strong>tags</strong> were
                  also varied: while sector (8.36) and location (9.27) received
                   high accuracy scores, method tags received more varied reviews (6.94).  

        </p>
        <br/>
        <img src="/ratings-chart.png" alt="ratings-chart"/>
        <br/> <br/>
        <p>
            To assess the <strong>hallucination</strong> problem, we also asked researchers if 
            summaries include incorrect information. There were several comments
             that limitations and theory summaries included errors. We 
             subsequently took them out. One author noted errors in the method
              description and one author in the sample / context description. 
              Importantly, there was no case of incorrect information among 
              “Findings”. But please keep in mind the small sample size for
               this quality and do not solely rely on the summaries. 
 
        </p>
        <br/> <br/>
        <h4>Scope of research</h4>
        <p>
            An important caveat is that we are only covering a limited number of 
            journals and time of publication. Ungated Research is therefore not 
            a good tool to conduct systematic literature reviews. 

            <br/> <br/>
            We are currently providing articles from ten journals for (roughly)
             the last five years. We are aware that we are contributing to the 
             “myth of the Top 5” with our selection and hope to expand the 
             scope, both in terms of journals and extending the period of time covered. 
        </p>
        <br/> <br/>
        <h4>Non peer-reviewed paper versions</h4>
        <p>
            For legal reasons, we base our summary on the most recent publicly available 
            working paper, not the peer-reviewed published version. The graph below 
            shows the time lag between the journal publication and publishing date 
            (calculated by subtracting the working paper year from the publication 
            year). The time lag is approximately one year or less for 49% of the 
            working papers. One explanation for this short time lag is that 
            authors often upload updated working paper versions of their research. 
            However, the AI-generated summaries may not capture any changes 
            made during the peer-review process. 
        </p>
        <br/>
        <img src="/time-lag.png" alt="time-lag-chart"/>
    </div>

    

    
    const otherSitesContent = <div>
        <h4>Repositories to promote the free dissemination of research</h4>
        <ul>
            <li><a href="https://www.ssrn.com/index.cfm/en/" target="_blank" rel="noreferrer">SSRN (Social Science Research Network)</a> - Primarily for social sciences, SSRN is a repository for researchers to share papers in economics, law, corporate governance, etc..</li>
            <li><a href="https://arxiv.org/" target="_blank" rel="noreferrer">arXiv</a> - Primarily for physics, mathematics, computer science, quantitative biology, quantitative finance, and statistics.</li>
            <li><a href="http://repec.org/" target="_blank" rel="noreferrer">RePEc (Research Papers in Economics)</a> - Provides bibliographic data on economic research and distributes working papers and links to published articles.</li>
        </ul>
        <br/>
        <h4>Research Summaries and literature reviews</h4>
        <ul>
            <li><a href="https://voxdev.org/" target="_blank" rel="noreferrer">Vox Dev</a> - Summarizes research and provides literature reviews with a focus on development economics.</li>
            <li><a href="https://www.povertyactionlab.org/publications" target="_blank" rel="noreferrer">JPAL</a> - Policy-focused summaries of experimental research with a focus on development economics.</li>
            <li><a href="https://developmentevidence.3ieimpact.org/" target="_blank" rel="noreferrer">3ie (International Initiative for Impact Evaluation)</a> - Provides an evidence hub of impact evaluation research, and systematic reviews with links to published articles.</li>
            <li><a href="https://www.pep-net.org/" target="_blank" rel="noreferrer">Pep-net</a> - Summarizes research and provides literature reviews with a focus on development economics.</li>
        </ul>
        <br/>
        <h4>AI-powered tools to summarize research</h4>
        <p>There are a range of services including Elicit, Consensus, and ScholarAI . These are paid services and only offer limited free plans.</p>
        <br/>
        <p>Email us with suggestions of other helpful websites to list.</p>
    </div>

    const contributorsContent = <div>
        <ul>
            <h4>PIs</h4> 
            <p>Martin Abel (Bowdoin College), Susan Godlonton (Williams College)</p>
            <br/>
            <h4>Web design/development and research analysis</h4>
            <p>Olivia Wirsching, Zane Bookbinder, Ulemj Munkhtur, Mingi Kang</p>
            <br/>
            <h4>Research Assistants</h4>
            <p>Brando Izquierdo, Tyler Lenk, Becky Walker, Jonathan Hartanto, Rafaela Delgado, Zia Saylor, 
                Daniel Carrera Mora, Simon Wainana, Aikedumanwen Izogie-Eghe
            </p>
            <br/>
            <h4>Volunteers</h4>
            <p>Jonathan Li, Rickey Karunadhara, Anh Nguyen, Shannon McCall, Alex Wiseman, 
                Maddy Kosmoski , Sam Wilson, Liam Hurtt, Chase Lenk, Joey Zheng, 
                Brendan Deppen, Julie Jansen, Raghad Mohamed, Sam Lieman, 
                Siddhu Srivatsan </p>
        </ul>
    </div>

    const getInvolvedContent = <div>
        <p>
            If you want to volunteer and offer at least 5 hours of your time to
             search for working papers of published articles, please email us at 
             ungatedresearch@outlook.com. 
        </p>
    </div>

    return (
        <div className="about-page">
            <div className="other-page-header">
                <h1>About this Site</h1>
                <div className="about-page-counts">
                    {loadingCounts ? (
                        <LoadingBar width={425} height={22} />
                    ) : (
                        <h4 style={{fontWeight: "600"}}>
                            {journalCount} journals &nbsp; | &nbsp;
                            {issueCount} issues &nbsp; | &nbsp;
                            {articleCount} articles
                        </h4>
                    )}
                </div>
            </div>
            <Dropdown title="What is the purpose of ungated research?" content={purposeContent} defaultExpanded/>
            <Dropdown title="How to use this website" content={howToUseContent}/>
            <Dropdown title="Method" content={methodContent} />
            <Dropdown title="Limitations" content={limitationsContent} />
            <Dropdown title="Other Helpful Websites" content={otherSitesContent} />
            <Dropdown title="Contributors" content={contributorsContent} />
            <Dropdown title="Get Involved" content={getInvolvedContent} />
        </div>
    );
}
export default AboutPage;
