import { useEffect } from "react";
import "./OtherPages.css";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";

function FAQPage() {
    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const summaryBasisContent = <p>
        These are AI-generated summaries of the introduction of the most recent publicly available working 
        paper versions. In economics, introductions are typically 4-6 
        pages long and provide a summary of the method, findings, and 
        contributions. We found that summarizing introductions provides 
        better results than summarizing entire articles. 
    </p>

    const mistakesDiscoveredContent = <p>
        You can email us (ungatedresearch@outlook.com) if you find incorrect
             information. Authors can email us if they want their 
             working paper or summary not to be included. 
     </p>

    const typeOfAiContent = <p>
        We tried several free and subscription-based AI models and settled 
        on Google Gemini (which is publicly available). 
    </p>

const promptContent = <div>
<p>
    Here is the prompt we used:
    <br/> <br/>
    "I will first give you instructions. Can you provide a non-technical 
    summary structured as follows: 2 to 5 bullet points on each of the 
    following categories except for main findings which should have 4 to 7
     bullet points? Remember that academic articles vary widely in their
    structure and content, so adapt your summary categories accordingly.
</p>
<ol>
    <li>Highlights: non-technical 1 sentence summary not exceeding 50 words that is easy to understand.</li>
    <li>Hypotheses or Research Questions: Summarize the main hypotheses or research questions the study aimed to address.</li>
    <li>Context and Sample or participants: If the introduction provides specific information about the study context, please specify the context or setting in which the research was conducted (e.g., geographical location, specific industry). If the introduction provides specific information about the sample, describe the characteristics of the study's sample or participants, including size, demographics, and any relevant criteria. Explain how data were collected, including instruments, surveys, interviews.</li>
    <li>Research Method: Describe the research method used to answer the research question and explain the rationale for their selection. This may include field experiments, lab experiments, simulations, modeling or analysis using quasi-experimental methods (e.g. Difference in difference, instrumental variables, regression discontinuity designs).  Summarize the statistical or analytical methods employed to analyze the data.</li>
    <li>Key Findings: Highlight specific key findings or insights that the study finds. This can include 3 to 5 bullet points. This must include specific magnitudes of findings.</li>
    <li>Discussion: Provide a summary of practical applications or policy implications and future research directions.</li>
    <li>Contributions: Summarize the article's main contributions to the existing literature.</li>
</ol>
<p>In terms of formatting, please make use of up to two levels of bullet 
    points and make key words bold."</p>
</div>

const proofreadContent = <p>
We conducted some quality checks (see “Limitations” section <Link to='/about'>here</Link>) but 
there is no quality check for individual summaries. 
</p>

const differenceFromWPContent = <p>
We only provide links to publicly available working papers people 
could also find for themselves (and summarize using AI services). 
Our goal is to reduce transaction costs. There are other websites 
that provide complementary services (e.g. synthesizing literatures) -
we have a list of these websites below.   
</p>

const legalContent = <p>
We consulted with experts in copyright law and confirmed that providing
information from journals’ publicly available tables of 
content is legal. AI-generated summaries are only based on publicly
available working papers. We found that, occasionally, publicly
available working papers still have a “copyright” designation. 
We tried to exclude these, but if you come across any copyrighted
    article, please email us (ungatedresearch@outlook.com). For working paper 
    series with download limitations, we added the link to the website version of papers.
</p>


    const faqContent = <div>
        <Dropdown  title="What are the summaries based on?" content={summaryBasisContent} />
        <Dropdown  title="What if I discover mistakes in the summaries or prefer that the working paper or summary not be provided?" content={mistakesDiscoveredContent} />
        <Dropdown  title="What type of AI is Used?" content={typeOfAiContent} />
        <Dropdown  title="What instruction / prompt did you use to generate the summary?" content={promptContent} />
        <Dropdown  title="Are the AI generated summaries proofread/edited/verified?" content={proofreadContent} />
        <Dropdown  title="What is the added value of this website?" content={differenceFromWPContent} />
        <Dropdown  title="Is this legal?" content={legalContent} />
    </div>

    return (
        <div className="faq-page">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-content">
                {faqContent}
            </div>
            
        </div>
    );
}
export default FAQPage;
