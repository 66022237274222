import "./SearchBar.css";
import React, { useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import CustomSelect from "./CustomSelect";
import debounce from "lodash/debounce";

function SearchBar({
    allJournals,
    allArticles,
    filters,
    setFilters,
    setFilteredJournals,
    setFilteredArticles,
    setFilteredJournalCount,
    setFilteredArticleCount,
    dropdown,
    placeholder,
    setLoadingSearchResults,
    filterToCategory
}) {
    // Managing Search Input
    const inputRef = useRef(null);
    const [searchInput, setSearchInput] = useState("");
   
    // Managing Filters 
    const [activeFilters, setActiveFilters] = useState([]);
    const [activeFilterCategories, setActiveFilterCategories] = useState([]);

    const getFilteredJournals = (lowerCaseSearchInput) => {
        const filteredJournals = [];
        for (let i = 0; i < allJournals.length; i++) {
            if (
                allJournals[i].search_text
                    .toLowerCase()
                    .indexOf(lowerCaseSearchInput) !== -1
            ) {
                filteredJournals.push(allJournals[i]);
            }
        }
        return filteredJournals
    }

    const getFilteredArticles = (lowerCaseSearchInput) => {
        const filteredArticles = [];
        for (let i = 0; i < allArticles.length; i++) {
            if(allArticles[i].search_text.indexOf(lowerCaseSearchInput) === -1) {
                continue;
            }

            let stillAlive = true;
            for (let j=0; j < activeFilterCategories.length; j++) {
                const activeCategoryTags = activeFilters.filter(filter => filterToCategory[filter] === activeFilterCategories[j]);
                let crossover = new Set(activeCategoryTags.filter(tag => allArticles[i].tags.has(tag)));
                if (!crossover.size) {
                    stillAlive = false;
                    break;
                }
            }

            if (stillAlive) {
                filteredArticles.push(allArticles[i]);
            }
        }
        return filteredArticles
    }

    // Handle a search input change by filtering journals and articles
    const handleChange = debounce((newSearchInput) => {
        
        // empty input, reset search results to only journals
        if (newSearchInput === "" && !activeFilters.length) {
            setFilteredJournals(allJournals);
            setFilteredArticles([]);
            setFilteredJournalCount(0);
            setFilteredArticleCount(0);
            setLoadingSearchResults(false);
            return;
        }

        // filter journals according to search input
        const lowerCaseSearchInput = newSearchInput !== "" ? newSearchInput.toLowerCase() : "";
        
        let filteredJournals = [];
        if (!activeFilters.length) {
            filteredJournals = getFilteredJournals(lowerCaseSearchInput);
        }
        setFilteredJournals(filteredJournals);
        setFilteredJournalCount(filteredJournals.length);

        // filter articles according to search input and journal filters
        const filteredArticles = getFilteredArticles(lowerCaseSearchInput);

        // sort articles by index of matching string in the article search text
        filteredArticles.sort((a, b) => {
            const aIndex = a.search_text.indexOf(lowerCaseSearchInput);
            const bIndex = b.search_text.indexOf(lowerCaseSearchInput);
            return aIndex - bIndex;
        });

        setFilteredArticles(filteredArticles);
        setFilteredArticleCount(filteredArticles.length);
        setLoadingSearchResults(false);
    }, 300);

    // expand the navbar search bar when clicked
    const focusInput = () => {
        inputRef.current.focus();

        if (dropdown) {
            const searchBarDropdown = document.querySelector(
                ".search-bar-input"
            );
            searchBarDropdown.style.width = "400px";
        }

        const targetElement = document.getElementById("search-area");
        const offset = 40;
        const targetPosition = targetElement.offsetTop - offset;

        window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
        });
    };

    // reset search results when a filter is selected/removed
    useEffect(() => {
        if (activeFilters.length !== 0) {
            handleChange(searchInput);
        } else {
            setFilteredJournals(allJournals);
            setFilteredArticles([]);
            setFilteredJournalCount(0);
            setFilteredArticleCount(0);

            if (typeof setLoadingSearchResults === 'function') {
                setLoadingSearchResults(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    // handle a selected/deselected filter
    const handleFilterChange = (event) => {
        setLoadingSearchResults(true);

        let filteredEvent = event.target.value.filter(item => item !== undefined);
        setActiveFilters(filteredEvent);
        let newActiveFilterCategories = [];
        for (let i=0; i < filteredEvent.length; i++) {
            newActiveFilterCategories.push(filterToCategory[event.target.value[i]]);
        }
        setActiveFilterCategories([...new Set(newActiveFilterCategories)]);
    };


    return (
        <div className={"flex search-bar-input-row"}>
            <>
                <FaSearch size={19} onClick={focusInput} className="react-icon" />
                <input
                    ref={inputRef}
                    className={
                        "subtle-text search-bar-input" +
                        (dropdown ? "" : " home-page-search-input")
                    }
                    value={searchInput}
                    onChange={(e) => {
                        setLoadingSearchResults(true);
                        setSearchInput(e.target.value);
                        handleChange(e.target.value);
                    }}
                    onClick={focusInput}
                    placeholder={placeholder}
                ></input>
            </>
           
            <CustomSelect options={filters} handleChange={handleFilterChange} value={activeFilters} setOptions={setFilters}/>
        </div>
    );
}
export default SearchBar;
