import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./JournalPage.css";
import { customFetch } from "../Utils/CustomFetch";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "./CustomAccordion.js";
import { Link } from "react-router-dom";
import LoadingBar from "../LoadingBar/LoadingBar.js";


function JournalPage() {
    const { journalId } = useParams();

    const [journalName, setJournalName] = useState("");
    const [volumes, setVolumes] = useState([]);
    const [recentArticles, setRecentArticles] = useState([]);
    // const [selectedLanguage, setSelectedLanguage] = useState("en"); 

    useEffect(() => {
        // Fetch journal issues
        customFetch(`/all_journal_issues/${journalId}`, {})
            .then((res) => res.json())
            .then((result) => {
                if (!Object.keys(result).length) {
                    window.location.href = "/";
                    return;
                }
                setVolumes(result);
            })
            .then(
                volumes.forEach((issues, index) => {
                    issues[2] = index === 0;
                })
            );

        // Fetch Journal Names
        customFetch(`/journal_name/${journalId}`, {})
            .then((res) => res.json())
            .then((result) => {
                if (!Object.keys(result).length) {
                    window.location.href = "/";
                    return;
                }
                setJournalName(result[0].journal_name);
            });

        // Fetch recent articles
        customFetch(`/recent_articles/${journalId}`, {})
            .then((res) => res.json())
            .then((result) => {
                if (!Object.keys(result).length) {
                    window.location.href = "/";
                    return;
                }
                setRecentArticles(result);
            });
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleLanguageChange = (language) => {
    //     setSelectedLanguage(language);
    // };

    return (
        <div className="journal-page">
            {journalName ? (
                <h1>{journalName}</h1>
            ) : (
                <LoadingBar width={500} height={55} />
            )}
            {/* <div className="language-switcher">
                <button onClick={() => handleLanguageChange("en")}>English</button>
                <button onClick={() => handleLanguageChange("es")}>Spanish</button>
                <button onClick={() => handleLanguageChange("fr")}>French</button>
            </div>  */}
            <div className="journal-content-container">
                <div className="volumes-list">
                    {Boolean(volumes.length) ? (
                        volumes.map((volume) => {
                            return (
                                <Accordion
                                    key={volume[0]}
                                    expanded={volume[2]}
                                    onChange={() => (volume[2] = !volume[2])}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                    >
                                        {journalId === '6' ? (
                                            <h5 className="volumes-tab">
                                                {volume[1][0].year}
                                            </h5>
                                        ): (
                                            <h5 className="volumes-tab">
                                                {volume[1][0].year} - VOLUME{" "}{volume[0]}
                                            </h5>
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {volume[1].map((issue) => {
                                            return (
                                                <Link
                                                    key={issue.id}
                                                    to={`/issue/${issue.id}`}
                                                >
                                                    {journalId === '6' ? (
                                                        <h3 className="issue">
                                                            Volume {issue.volume}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="issue">
                                                            Issue{" "}
                                                            {Math.max(
                                                                issue.issue,
                                                                1
                                                            )}
                                                        </h3>
                                                    )}
                                                </Link>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                    ) : (
                        <div className="issue-loading-bars">
                            {Array.from({ length: 7 }, (_, index) => (
                                <LoadingBar
                                    key={index}
                                    width={330}
                                    height={62}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="recent-articles">
                    <h4>Recent Articles</h4>
                    {Boolean(recentArticles.length) ? (
                        recentArticles.map((article) => {
                            return (
                                <div
                                    key={article.id}
                                    className="recent-article"
                                >
                                    <Link to={`/article/${article.id}`}>
                                        <h3>{article.title}</h3>
                                    </Link>
                                    <h5>{article.authors}</h5>
                                    {Boolean(article.year) && (
                                        <h6>{article.year}</h6>
                                    )}                          
                                    {/* <div className="article-summary">
                                        {selectedLanguage === "en" && <p>{article.summary_text}</p>}
                                        {selectedLanguage === "es" && <p>{article.summary_text_es}</p>}
                                        {selectedLanguage === "fr" && <p>{article.summary_text_fr}</p>}
                                    </div>  */}
                                </div>
                            );
                        })
                    ) : (
                        <div className="recent-articles-loading-bars">
                            {Array.from({ length: 7 }, (_, index) => (
                                <LoadingBar
                                    key={index}
                                    width={600}
                                    height={156}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default JournalPage;
