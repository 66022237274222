import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardLink = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <div className="copy-to-clipboard">
            <CopyToClipboard text={text} onCopy={handleCopy}>
                {/* Can add disableRipple to IconButton if we want! */}
                <IconButton>
                    {copied ? (
                        <FaCheck className="check-icon" size={20} />
                    ) : (
                        <FaRegCopy
                            className="copy-to-clipboard-icon"
                            size={20}
                        />
                    )}
                </IconButton>
            </CopyToClipboard>
        </div>
    );
};

export default CopyToClipboardLink;
