import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function Dropdown({ title, content, defaultExpanded, smallTitle }) {
    const [expanded, setExpanded] = useState(defaultExpanded);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={toggleExpanded}
            style={{ maxWidth: "800px" }}
			sx={{
				'&:before': {
					display: 'none',
				}
			}}
            elevation={0}
		>
            <AccordionSummary
                expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
                style={{ height: 64, overflow: "hidden" }}
            >
                {smallTitle ? <h5>{title}</h5> : <h2>{title}</h2>}
            </AccordionSummary>
            <AccordionDetails style={{ maxWidth: "768px" }}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
}

export default Dropdown;
