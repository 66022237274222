import "./HomePage.css";
import { useState, useEffect } from "react";
import { ListSubheader, Select } from "@mui/material";
import { FormControl, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

function CustomSelect({
	value, widthPercent, height, minWidth,
	maxWidth, options, handleChange,
	borderRadius, isNavBar
}) {

	const [showing, setShowing] = useState([]);
	
	useEffect(() => {
		if(!showing.length && options) {
			let show = {};
			options.forEach(option => {
				show[option.label] = false;
			});
			setShowing(show);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	widthPercent = widthPercent || "100%";
	height = height || "40px";
	borderRadius = borderRadius || "0px";
	minWidth = minWidth || "none";
	maxWidth = maxWidth || "none";
	
	function handleShowMore(label) {
		setShowing({...showing, [label]: !showing[label]});
	}
  
	return (
		<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
			<Select
				className= "custom-select"
				multiple={true}
				value={value}
				placeholder=""
				IconComponent={FilterAltIcon}
				renderValue={() => ''}
				disableUnderline
				inputProps={{
					sx: {
						width: "30px",
						height: "30px",
					},
				}}
				MenuProps={{
					// anchorOrigin: {
					// 	vertical: "bottom",
					// 	horizontal: "left"
					// },
					// transformOrigin: {
					// 	vertical: "top",
					// 	horizontal: "left",
					// },
					// getcontentanchorel: null,
					// PaperProps: {
					// 	style: {
					// 		marginTop: marginTop,
					// 	}
					// }
				}}
				style={{
					// border: "1px solid white", color: "black", fill:"white",
					// backgroundColor: "white", width: widthPercent, minWidth: minWidth, maxWidth: maxWidth,
					// borderRadius: borderRadius, height: height, padding: 0, marginLeft: "0rem", 
					// marginTop: "0rem", ...additionalStyles
					}}
				sx={{
					".css-14ycarb-MuiInputBase-root-MuiInput-root-MuiSelect-root::before": {
						display: "none"
					},
					"& .MuiOutlinedInput-root": {
						borderRadius: "0px",
						padding: "0",
						border: "none",
						// height: height,
						// minWidth: minWidth,
						paddingLeft: "10px",
						boxShadow: "none",
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: "none",
						outline: '0',
					},
					'.MuiSvgIcon-root ': {
						fill: "black !important",
						transform: 'none'
					},
					"& button.MuiButtonBase-root" : {
						visibility: "visible",
						transform: 'none'
					},
					"& .MuiPaper-root": {
						minWidth: "300px",
						marginTop: "15px",
					},
					"& .MuiMenuItem-root": {
						minHeight: "unset !important",
					},
					".MuiInput-underline": {
						display: "none"
					},
					"& .MuiInputBase-root::before": {

						content: '""', // Set content to empty string
					},
					"& .MuiMenu-paper": {
						width: "100%"
					}
					// ".MuiSelect-nativeInput": {
					// 	// display: "none"
					// 	width: "30px",
					// 	height: "30px"
					// }
				}}
				options={options}
				onChange={handleChange}
			>
				{options && options.map((group) => [ 
					<ListSubheader>{group.label}</ListSubheader>,
					showing[group.label] ? 
						(group.options.map((option) => {
							return (
								<MenuItem
									className="searchBar-menuItem"
									key={option.id}
									value={option.id}
								>
									<Checkbox 
										checked={value.indexOf(option.id) > -1}
									/>
									<ListItemText primary={option.label} />
								</MenuItem>
							)
						}))

						:

						(group.options.slice(0,3).map((option) => {
							return (
								<MenuItem
									className="searchBar-menuItem"
									key={option.id}
									value={option.id}
								>
									<Checkbox 
										checked={value.indexOf(option.id) > -1} 
									/>
									<ListItemText primary={option.label} />
								</MenuItem>
							)
						})),
						<Button onClick={() => handleShowMore(group.label)}>
							{showing[group.label] ? "Show Less" : "Show More"}
						</Button>
				])}
			</Select>
		</FormControl>
		
	);
}

export default CustomSelect;
