import { useNavigate, Link } from "react-router-dom";
import "./SearchBarResults.css";
import LoadingBar from "../LoadingBar/LoadingBar";
import CircularProgress from '@mui/material/CircularProgress';

function SearchBarResults({
    filteredArticles,
    filteredJournals,
    filteredJournalCount,
    filteredArticleCount,
    loadingJournalData,
    homePage,
    loadingSearchResults
}) {
    const navigate = useNavigate();

    const handleLinkClick = (link) => {
        navigate(link);
        window.location.reload();
    };

    let topClassName = homePage
        ? "nav-search-container search-home-page"
        : "nav-search-container";

    return (
        <div className={topClassName}>
            {loadingSearchResults ? (
                    <div className="circular-progress">
                        <CircularProgress />
                    </div>
            ) : (
                <>
                    {/* Journals title and Journal count */}
                    {Boolean(!filteredArticles.length && !filteredJournals.length) && 
                        <h4 className="journal-section-heading">No Results</h4>
                    }
                    {Boolean(filteredArticles.length) && (
                        <h4
                            className={
                                Boolean(filteredJournals.length)
                                    ? "journal-section-heading"
                                    : "journal-section-heading journals-heading-empty"
                            }
                        >{`Journals (${filteredJournalCount})`}</h4>
                    )}

                    {/* List of Filtered Journals */}
                    <div
                        className={
                            Boolean(filteredJournals.length)
                                ? "home-page-journals"
                                : "home-page-journals journals-text-empty"
                        }
                    >
                        {Boolean(loadingJournalData) ? (
                            <>
                                {Array.from({ length: 7 }, (_, index) => (
                                    <LoadingBar key={index} width={233} height={128} />
                                ))}
                            </>
                        ): (
                            <>
                                {filteredJournals.map((journal) => {
                                    return (
                                        <Link
                                            key={journal.id}
                                            className="journal-link-button"
                                            onClick={() =>
                                                handleLinkClick(
                                                    `/journal/${journal.id}`
                                                )
                                            }
                                        >
                                            <p className="journal-link-text">
                                                {journal.journal_name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </>
                        )}
                    </div>

                    {/* List of filtered Articles */}
                    {Boolean(filteredArticles.length) && (
                        <div className="home-page-articles">
                            <h4>{`Articles (${filteredArticleCount})`}</h4>
                            {filteredArticles.map((article) => {
                                return (
                                    <div className="article" key={article.id}>
                                        <Link
                                            key={article.id}
                                            onClick={() =>
                                                handleLinkClick(
                                                    `/article/${article.id}`
                                                )
                                            }
                                        >
                                            <h3>{article.title}</h3>
                                        </Link>
                                        <h5>{article.authors}</h5>
                                        <h6>{`${article.journal_name}, ${article.year}`}</h6>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default SearchBarResults;
