import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./IssuePage.css";
import { customFetch } from "../Utils/CustomFetch";
import { Link } from "react-router-dom";
import LoadingBar from "../LoadingBar/LoadingBar";

function IssuePage() {
    const { issueId } = useParams();
    const [issueData, setIssueData] = useState({
        // issue: 0,
        // journal: "",
        // journal_abbreviation: "",
        // volume: 0,
        // year: 0,
    });
    const [issueArticles, setIssueArticles] = useState([]);

    useEffect(() => {
        // Fetch issue data
        customFetch(`/issue_info/${issueId}`)
            .then((res) => res.json())
            .then((result) => {
                if (!Object.keys(result).length) {
                    window.location.href = "/";
                    return;
                }
                setIssueData(result);
            });

        // Fetch recent articles
        customFetch(`/issue_articles/${issueId}`)
            .then((res) => res.json())
            .then((result) => {
                if (!Object.keys(result).length) {
                    window.location.href = "/";
                    return;
                }
                setIssueArticles(result);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="issue-page">
            <div className="issue-page-title">
                {Object.keys(issueData).length ? (
                    <>
                        <h1>{issueData.journal}</h1>
                        {issueData.issue ? (
                            <h2>
                                Volume {issueData.volume} - Issue {issueData.issue}
                            </h2>
                        ) : (
                            <h2>Volume {issueData.volume}</h2>
                        )}
                    </>
                ) : (
                    <>
                        <LoadingBar
                            width={500}
                            height={60}
                            additionalStyles={{ marginBlock: "13px" }}
                        />
                        <LoadingBar width={250} height={40} />
                    </>
                )}
            </div>
            <div className="issue-articles">
                {issueArticles.length ? (
                    <>
                        {issueArticles.map((article) => (
                            <div key={article.id} className="article">
                                {/* <div className="flex">
                                    <p className="tag">Discipline</p>
                                    <p className="tag">Country</p>
                                </div> */}
                                <Link to={`/article/${article.id}`}>
                                    <h3>{article.title}</h3>
                                </Link>
                                <h5>{article.authors}</h5>
                                {Boolean(article.year) && (
                                    <h6>{article.year}</h6>
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="issue-loading-bars">
                        {Array.from({ length: 7 }, (_, index) => (
                            <LoadingBar key={index} width={800} height={90} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default IssuePage;
