import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./NavBar.css";
// import SearchBar from "../HomePage/SearchBar.js";
// import { FaHome } from "react-icons/fa";
// import SearchBarResults from "../HomePage/SearchBarResults.js";
// import { customFetch } from "../Utils/CustomFetch.js";
import HamburgerMenu from "./HamburgerMenu.js";
// import { FaSearch } from "react-icons/fa";

function Navbar() {
    const location = useLocation();

    // const [showSearchResults, setShowSearchResults] = useState(false);

    // const [allJournals, setAllJournals] = useState([]);
    // const [allArticles, setAllArticles] = useState([]);

    // const [filteredJournals, setFilteredJournals] = useState([]);
    // const [filteredArticles, setFilteredArticles] = useState([]);
    // const [filteredJournalCount, setFilteredJournalCount] = useState(0);
    // const [filteredArticleCount, setFilteredArticleCount] = useState(0);

    // const [loadingJournalData, setLoadingJournalData] = useState(true);

    // const handleClick = () => {
    //     setShowSearchResults(true);
    // };

    useEffect(() => {
        // Fetch search bar data
        // customFetch("/search_bar_data", {})
        //     .then((res) => res.json())
        //     .then((result) => {
        //         setAllJournals(result.journals);
        //         setAllArticles(result.articles);
        //         setFilteredJournals(result.journals);
        //         setLoadingJournalData(false);
        //     });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USED FOR NAV SEARCH BAR
    // useEffect(() => {
        // const handleOutsideClick = (e) => {
        //     if (e.target.className !== "subtle-text search-bar-input") {
        //         setShowSearchResults(false);

      

        //         const searchBarLength = document.querySelector(
        //             ".search-bar-input"
        //         );

        //         searchBarLength.style.width = "150px";
                
        //     }
        // };

        // document.body.addEventListener("click", handleOutsideClick);

    //     return () =>
    //         document.body.removeEventListener("click", handleOutsideClick);
    // }, []);

    let navbarClass = "navbar flex";
    if (location.pathname === "/") {
        navbarClass += " navbar-home";
    }

    return (
        <div className={navbarClass} bg="dark" variant="dark">
             
            <div className="left-side">
                <div className="title-text navbar-desktop">
                    <Link  to="/">
                        <img src="/UngatedResearch.png" alt="Ungated logo"/>
                    </Link>    
                </div>
                <div className="navbar-mobile-left">
                    <HamburgerMenu />   
                    <div className="title-text-mobile navbar-mobile">
                            <Link  to="/">
                            <img src="/UngatedResearch.png" alt="Ungated logo"/>
                            </Link>    
                        
                    </div>
                </div>
               {/* REMOVING SEARCH BAR FROM NAV - CAN ADD BACK IN FUTURE */}
                {/* <div
                    className="search-link nav-search-div"
                    onClick={handleClick}
                    >
                        <SearchBar
                            allJournals={allJournals}
                            allArticles={allArticles}
                            setFilteredJournals={setFilteredJournals}
                            setFilteredArticles={setFilteredArticles}
                            setFilteredJournalCount={setFilteredJournalCount}
                            setFilteredArticleCount={setFilteredArticleCount}
                            dropdown={true}
                            placeholder={"Search"}
                        />

                        {showSearchResults && (
                            <SearchBarResults
                                filteredArticles={filteredArticles}
                                filteredJournals={filteredJournals}
                                filteredJournalCount={filteredJournalCount}
                                filteredArticleCount={filteredArticleCount}
                                loadingJournalData={loadingJournalData}
                                homePage={false}
                            />
                    )}
                </div> */}
            </div>
            <div className="right-side flex subtle-text">
                <Link className="link" to="/about">About this site</Link>
                <Link className="link" to="/faq">FAQ</Link>
                <Link className="link" to="/contact">Contact</Link>
            </div>
            
        </div>
    );
}

export default Navbar;